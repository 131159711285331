@import "styles/utils/index";

.biFormContainer {
  padding: 3% 10% 5%;
  background: #fff;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 1110px) {
    padding: 1rem 1.5rem;
  }

  @media (max-width: 576px) {
    padding: 16px;
  }

  .innerContainer {
    color: #141414;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    height: 80vh;

    @media (max-width: 768px) {
      height: 85vh;
      padding-bottom: 0;
    }

    :global {
      .ant-form {
        height: 100%;
        padding: 0;

        @media (max-width: 576px) {
          padding: 0;
        }
      }
    }

    .scroll {
      height: 100%;
      padding: 24px;
      overflow-y: scroll;
      overflow-x: hidden;

      @include custom-scrollbar(large);

      @media (max-width: 576px) {
        padding: 16px;

        @include custom-scrollbar(small);
      }
    }

    // background-color: #fafafa;

    .formComponents {
      // width: 100%;
      // padding: 16px;

      // border: 1px solid #bfbfbf;
      // border-radius: 6px;
      // background-color: white;
      margin-bottom: 24px;

      :global {
        .ant-form-item {
          margin: 0;
        }

        .ant-form-item-label > label {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          display: flex;
          align-items: flex-start;
          gap: 8px;

          @media (max-width: 576px) {
            font-size: 16px;
          }
        }

        // .ant-form-item-control {
        //   left: 16px;
        // }
      }

      // box-shadow: 0 8px 12px rgb(0 0 0 / 8%);
    }
  }

  .title {
    font-size: 20px;
    color: #141414;
    line-height: 24px;
    font-weight: 600;
  }

  .description {
    font-size: 16px;
    color: #141414;
    line-height: 24px;
    margin-bottom: 28px;
  }

  button {
    height: 5%;
    border-radius: 4px;
  }

  .formComponents .rating {
    :global {
      @apply text-secondary-6;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 20%;
}

.submitBtn {
  width: fit-content;

  /* stylelint-disable-next-line no-descending-specificity */
  :global {
    .ant-btn {
      @apply px-6 py-3;
      @apply text-subtitle text-neutral-1;

      @screen md {
        @apply px-8 py-3;
        @apply text-base;
      }
    }
  }
}

.range {
  :global {
    .ant-input-number {
      @apply h-10 w-20 md:w-40 flex items-center rounded-md;
    }

    .ant-input-number-input {
      @apply text-center;
    }

    .ant-row {
      flex-direction: row;
    }

    .ant-input-number-input-wrap {
      @apply w-full h-10 flex items-center;
    }

    .ant-input-number-disabled {
      .ant-input-number-input {
        @apply cursor-auto;
      }

      @apply bg-white  text-neutral-12 cursor-auto;
    }
  }
}

.longText {
  :global {
    .ant-input[disabled] {
      @apply bg-white text-neutral-12 cursor-auto;
    }
  }
}
