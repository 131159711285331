/* stylelint-disable no-descending-specificity */
// @import "globals";

.container {
  :global {
    .ant-btn-primary,
    .ant-btn-primary:hover,
    .ant-btn-primary:focus,
    .ant-btn-primary:active {
      border: none;
    }

    .ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
      @apply bg-neutral-5;
      @apply border-neutral-5;
      @apply text-neutral-8;
    }
  }
}

.primary {
  :global {
    .ant-btn {
      @apply h-auto w-auto;
      @apply rounded-md;
      @apply text-neutral-1;
      @apply bg-primary-6;
    }

    .ant-btn:focus,
    .ant-btn:active {
      @apply bg-primary-7;
    }

    .ant-btn:hover {
      @apply bg-primary-5;
    }
  }
}

.secondary {
  :global {
    .ant-btn {
      @apply h-auto w-auto;
      @apply rounded-md;
      @apply text-neutral-1;
      @apply bg-secondary-6;
    }

    .ant-btn:focus,
    .ant-btn:active {
      @apply bg-secondary-5;
    }

    .ant-btn:hover {
      @apply bg-secondary-4;
    }
  }
}

.tertiary {
  :global {
    .ant-btn {
      @apply h-auto w-auto;
      @apply rounded-md;
      @apply text-neutral-1;
      @apply bg-tertiary-6;
    }

    .ant-btn:focus,
    .ant-btn:active {
      @apply bg-tertiary-7;
    }

    .ant-btn:hover {
      @apply bg-tertiary-5;
    }
  }
}

.buttonIcon {
  @apply text-heading-6 text-neutral-1;

  line-height: 0;
}

.iconButton {
  @apply p-8;
  @apply text-heading-6;
  @apply rounded-md;
  @apply flex-row-reverse;
  @apply flex items-center gap-2;
}
